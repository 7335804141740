import { render, staticRenderFns } from "./create-note.vue?vue&type=template&id=785f8efe"
import script from "./create-note.vue?vue&type=script&lang=js"
export * from "./create-note.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClientSelector: require('/home/runner/work/plannr-nuxt/plannr-nuxt/components/ClientSelector.vue').default,ClientCaseSelector: require('/home/runner/work/plannr-nuxt/plannr-nuxt/components/ClientCaseSelector.vue').default,ClientTaskSelect: require('/home/runner/work/plannr-nuxt/plannr-nuxt/components/ClientTaskSelect.vue').default,ClientPlanSelect: require('/home/runner/work/plannr-nuxt/plannr-nuxt/components/ClientPlanSelect.vue').default,RedactorInput: require('/home/runner/work/plannr-nuxt/plannr-nuxt/components/RedactorInput.vue').default,ErrorBlock: require('/home/runner/work/plannr-nuxt/plannr-nuxt/components/ErrorBlock.vue').default})
